.pointer {
    cursor: pointer;
}

.strike {
    text-decoration: line-through;
}

.form-hint {
    color: #b5b5b5;
    font-size: 70%;
}

.has-text-smoose-gray {
    color: #888888
}


ion-icon {
    margin-top: 4px;
}

.icon ion-icon {
    margin-top: 0;
}
.rotate-180 {
    transform: rotate(180deg);
}

.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 70px;
    height: 35px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 30px;
    height: 30px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
    width: 60px;
}

/* for login buttons */
.is-blue {
    background: #45B4DC !important;
}

.menu__content{
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: .5rem;
    box-shadow: 0 2px 4px rgba(3,74,40,.15);
    padding: .75rem;
    width: 900px;
    cursor: pointer;
}

.menu__img{
    height: 110px;
    border-radius: 5%;
    align-self: center;
}

.button-active:hover .button-default{
    display: block;
}
.button-default {
    display: none;
}

.categories {
    overflow-x: auto;
}
.hei {
    height: 80vh;
}
.wei{
    width: 140vh;
}
.main_breadcrumb > ul {
    align-items: center;
}

.main_breadcrumb > ul > li > div {
    margin: 0 10px;
    height: 10px;
    border: none;
}

.main_breadcrumb > ul > li > div > select {
    padding: 0 0 0 10px;
    background: none;
}

#category {
    background: none;
}

#childCategory {
    background: none;
}
